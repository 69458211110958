.map {
	width: 100%;
	margin: 100px 0;
	height: 400px;
}

.contact-box {
	@extend %flexbox;
	@include flex-flow(wrap);
	max-width: 750px;
	margin: 0 auto;

	form {
		width: 100%
	}

	p {
		margin: 0;
	}

	.contact-form, .contact-details  {
		@media #{$desktop} {
			-webkit-flex: 1;
			flex: 1;
		}
		margin: 0 30px;
	}

	.contact-details {
		font-size: .9em;
	}

	.contact-radio-1 {
		display: inline-block;
		width: 60%;
	}

	.contact-radio-2 {
		display: inline-block;
		width: 20%;
	}

	.contact-radio-3 {
		display: inline-block;
		width: 10%;
	}
}
